<template>
    <v-container>
        <v-card class="mx-auto my-12 pa-6" max-width="800" v-if="caseDetails.caseNumber != ''">
            <v-card-title class="text-h4">Detalle de Denuncia</v-card-title>
            <v-card-subtitle>Número de Identificación: {{ caseDetails.unique_id }}</v-card-subtitle>
            <v-divider></v-divider>

            <v-row class="mt-4">
                <v-col cols="12" md="6">
                    <v-card>
                        <v-card-title>Información General</v-card-title>
                        <v-list>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Empresa:</v-list-item-title>
                                    <v-list-item-subtitle>{{ caseDetails.company }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="caseDetails.compliant_type_id !== 2">
                                <v-list-item-content>
                                    <v-list-item-title>Nombre:</v-list-item-title>
                                    <v-list-item-subtitle>{{ caseDetails.name }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="caseDetails.email !== 'no@email.com'">
                                <v-list-item-content>
                                    <v-list-item-title>Correo:</v-list-item-title>
                                    <v-list-item-subtitle>{{ caseDetails.email }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Fecha Acontecimiento:</v-list-item-title>
                                    <v-list-item-subtitle>{{ formatDate(caseDetails.date) }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Fecha Ingreso:</v-list-item-title>
                                    <v-list-item-subtitle>{{ formatDate(caseDetails.register_date)
                                        }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Tipo de Denuncia:</v-list-item-title>
                                    <v-list-item-subtitle>{{ caseDetails.type }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Estado:</v-list-item-title>
                                    <v-list-item-subtitle>{{ caseDetails.status }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-col>

                <v-col cols="12" md="6">
                    <v-card>
                        <v-card-title>Detalle</v-card-title>
                        <v-card-text>{{ caseDetails.description }}</v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-divider class="my-4"></v-divider>

            <!-- Stepper -->
            <v-stepper v-model="step" alt-labels>
                <v-stepper-header>
                    <v-stepper-item title="Creado" value="1"></v-stepper-item>

                    <v-divider></v-divider>

                    <v-stepper-item title="Aceptado" value="2"></v-stepper-item>

                    <v-divider></v-divider>

                    <v-stepper-item title="En Revisión" value="3"></v-stepper-item>

                    <v-divider></v-divider>

                    <v-stepper-item title="Resolución" value="4"></v-stepper-item>

                    <v-divider></v-divider>

                    <v-stepper-item title="Finalizada" value="5"></v-stepper-item>
                </v-stepper-header>

            </v-stepper>

            <v-divider class="my-4"></v-divider>

            <v-row justify="center">
                <v-btn @click="goBack" color="primary" class="ma-2">Volver</v-btn>
                <v-btn @click="downloadFile" color="primary" class="ma-2"
                    v-if="caseDetails.status_id >= 5">Reporte</v-btn>

            </v-row>
        </v-card>
    </v-container>
</template>

<script>
import axiosInstance from '../axios';

export default {
    name: 'CaseDetailPublic',
    props: {
        initialUniqueId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            caseDetails: {
                caseNumber: '',
                email: '',
                unique_id: this.initialUniqueId,
                date: '',
                type: '',
                status: '',
                status_id: '',
                description: '',
                compliant_type_id: '',
                name: '',
                register_date: '',
                company: '',
            },
            step: 0, // Inicializar con el primer estado, ajustar según sea necesario
        };
    },
    created() {
        const caseId = this.initialUniqueId;
        console.log('Identificador único:', caseId);
        this.getCaseDetails(caseId);
    },
    methods: {
        formatDate(date) {
            const dateObj = new Date(date);
            const year = dateObj.getFullYear();
            const month = String(dateObj.getMonth() + 1).padStart(2, '0');
            const day = String(dateObj.getDate()).padStart(2, '0');
            return `${day}-${month}-${year}`;
        },
        async getCaseDetails(caseId) {

            let name, email, date, compliant_type_desc, status_desc, detail, status_id, unique_id, register_date, company_desc, resolutor_detail, compliant_type_id;

            try {

                const responseDetail = await axiosInstance.get(`/compliant-user.php?unique_id=${caseId}`);
                console.log('Detalles del caso:', responseDetail.data);
                ({ name, email, date, compliant_type_desc, status_desc, detail, status_id, unique_id, register_date, company_desc, resolutor_detail, compliant_type_id } = responseDetail.data);

            } catch (error) {
                console.error('Error al obtener caso:', error);
                this.$router.push({ name: 'SearchFormPublic', query: { noData: true, search: caseId } });
            }

            this.caseDetails = {
                caseNumber: caseId,
                email: email,
                date: date,
                unique_id: unique_id,
                type: compliant_type_desc,
                status: status_desc,
                description: detail,
                status_id: status_id,
                name: name,
                register_date: register_date,
                company: company_desc,
                compliant_type_id: compliant_type_id,
                resolutor_detail: resolutor_detail,
            };

            console.log('Detalles del caso:', this.caseDetails);

            this.step = status_id;
            this.step--; // Ajustar el índice del stepper
        },

        goBack() {
            this.$router.push({ name: 'SearchFormPublic' });
        },
        downloadFile() {
            console.log('Descargar archivo');
            this.$router.push({ name: 'CaseDetailPrint', params: { uniqueId: this.caseDetails.unique_id } });
        }
    },
};
</script>

<style scoped>
.v-card {
    background-color: #f9f9f9;
    /* Color de fondo claro para el card */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Sombra suave */
}

.v-card-title {
    color: #1976D2;
    /* Color primario de Vuetify */
}

.v-list-item-title {
    font-weight: bold;
}

.v-list-item-subtitle {
    font-size: 1rem;
}

.v-divider {
    border-top: 2px solid #1976D2;
    /* Color de la línea de división */
}

.v-btn {
    font-weight: bold;
}

.v-stepper {
    margin-top: 20px;
}

.v-stepper-step {
    font-weight: bold;
}

.v-stepper-content {
    padding: 16px;
}
</style>