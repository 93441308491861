<template>
    <v-container>
        <v-card class="mx-auto my-12 pa-6" max-width="400">
            <v-card-title class="text-h5">Iniciar Sesión</v-card-title>
            <v-divider></v-divider>

            <v-form @submit.prevent="login">
                <v-text-field v-model="username" label="Usuario" type="string" required></v-text-field>
                <v-text-field v-model="password" label="Contraseña" type="password" required></v-text-field>
                <v-btn type="submit" color="primary" class="ma-2">Iniciar Sesión</v-btn>
                <v-alert v-if="error" type="error" class="mt-4">{{ error }}</v-alert>
            </v-form>
        </v-card>
    </v-container>
</template>

<script>
import axiosInstance from '../axios';

export default {
    name: 'UserLogin',
    data() {
        return {
            username: '',
            password: '',
            error: '',
        };
    },
    methods: {
        async login() {
            try {
                const response = await axiosInstance.post(
                    '/user-resolutor.php',
                    new URLSearchParams({
                        username: this.username,
                        password: this.password,
                    }),
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        },
                    }
                );
                // Guardar el token en el almacenamiento local
                localStorage.setItem('authToken', response.data.token);
                localStorage.setItem('username', response.data.fullname);
                localStorage.setItem('user_id', response.data.id);
                localStorage.setItem('user_type_id', response.data.user_type_id);
                window.location.href = 'https://compliance.cmcordillera.cl/';
            } catch (err) {
                this.error = 'Credenciales inválidas. Intenta de nuevo.';
                console.error('Error durante el login:', err);
            }
        }
    }
};
</script>

<style scoped>
.v-card {
    max-width: 400px;
}

.v-card-title {
    color: #1976D2;
}

.v-alert {
    text-align: center;
}
</style>
