<template>
    <v-container>
        <v-card class="mx-auto my-12 pa-6" max-width="1000">
            <v-card-title class="text-h5">Denuncias Realizadas</v-card-title>
            <v-form>
                <v-text-field label="Identificador" type="string" variant="outlined" justify="center"
                    v-model="form.unique_id" :rules="[rules.required]" />
                <v-row justify="center">
                    <v-col cols="auto">
                        <v-btn type="submit" color="primary" @click="submitForm">Buscar</v-btn>
                    </v-col>
                </v-row>
            </v-form>
            <v-divider class="my-4"></v-divider>
            <v-alert v-if="$route.query.noData" type="info" border="left" color="error">
                No se encontraron detalles para el caso.
            </v-alert>
        </v-card>
    </v-container>
</template>

<script>
export default {
    name: 'SearchFormPublic',
    data() {
        return {
            form: {
                unique_id: "",

            },
            rules: {
                required: value => !!value || 'El campo identificador es requerido.',
            }
        };
    },
    mounted() {
        // Leer el valor de la query "search" y asignarlo a unique_id si está presente
        const searchQuery = this.$route.query.search;
        if (searchQuery) {
            this.form.unique_id = searchQuery;
        }
    },
    methods: {
        async submitForm() {
            console.log(this.form);
            try {
                const uniqueId = this.form.unique_id;
                console.log("Identificador único:", uniqueId);
                this.$router.push({
                    name: 'CaseDetailPublic',
                    params: { uniqueId: uniqueId }
                });
            } catch (error) {
                console.error("Error al enviar el formulario:", error);
            }
        },
    },

};
</script>